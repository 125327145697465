import React from 'react';
import { navigate } from 'gatsby-link';

export default function NotFoundPage({location })  {
  const basepath = location.pathname.match("\/([a-zA-Z0-9!?@\#$%\^\&*\]{0,})");
  const isBrowser = () => typeof window !== "undefined"
  if(basepath[1]==="fr"||basepath[1]==="de"){
    isBrowser() && navigate (`/${basepath[1]}/404`);
  }
  else
  {
    isBrowser() && navigate (`/en/404`);
  }
  return <></>;
}
